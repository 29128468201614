import { create } from "zustand";

type VisitorProps = {
  visitorPasses: { [key: string]: number };
  setVisitorPasses: (visitors: any) => void;
};

const useVisitorStore = create<VisitorProps>((set, get) => ({
  visitorPasses: {},
  setVisitorPasses: (visitors) => {
    const visitorMap: { [key: string]: number } = {};

    for (const visitor of visitors) {
      if (!visitorMap[visitor.vpNumber]) {
        visitorMap[visitor.vpNumber] =
          visitor.status === 2 || visitor.status === 4 ? 1 : 0;
      } else {
        visitorMap[visitor.vpNumber] +=
          visitor.status === 2 || visitor.status === 4 ? 1 : 0;
      }
    }

    set({ visitorPasses: visitorMap });
  },
}));

export default useVisitorStore;
